import styles from './VariantSelect.module.sass';
import { useEffect, useState } from 'react';

export default function VariantSelect(props) {
  const { products, selectedVariant, onChange, defaultVariantId, sizeGuide } = props;

  const [options, setOptions] = useState([]);

  // Helper function for size comparison
  const getSizeOrder = (size) => {
    // Common size ordering
    const sizeOrder = {
      'X-Small': 1,
      XS: 1,
      Small: 2,
      S: 2,
      Medium: 3,
      M: 3,
      Regular: 4,
      Large: 5,
      L: 5,
      'X-Large': 6,
      XL: 6,
      'XX-Large': 7,
      XXL: 7,
      Universal: 8,
    };

    return sizeOrder[size] || 999; // Default to high number for unknown sizes
  };

  useEffect(() => {
    if (!products || products.length === 0) return;

    // Get unique variant values from products
    const uniqueVariants = new Map();

    products.forEach((product) => {
      if (product.variantValue && !uniqueVariants.has(product.variantValue)) {
        uniqueVariants.set(product.variantValue, {
          name: product.variantValue,
          imageSrc: product.variantImage || product.colorimage,
          variantName: product.variantName || 'Size',
          id: product.id,
        });
      }
    });

    // Convert map to array
    const variantOptions = Array.from(uniqueVariants.values());

    // Sort options by size order and then by default variant
    const sortedOptions = variantOptions.sort((a, b) => {
      // First prioritize size ordering
      const sizeOrderA = getSizeOrder(a.name);
      const sizeOrderB = getSizeOrder(b.name);

      if (sizeOrderA !== sizeOrderB) {
        return sizeOrderA - sizeOrderB;
      }

      // Then consider default variant as secondary sort criterion
      if (a.id === defaultVariantId?.toString()) return -1;
      if (b.id === defaultVariantId?.toString()) return 1;

      // If neither is the default and they have the same size order, sort alphabetically
      return a.name.localeCompare(b.name);
    });

    setOptions(sortedOptions);

    // If we have options and no selected variant yet, set the default/first option
    if (sortedOptions.length > 0 && !selectedVariant?.name) {
      const defaultOption =
        sortedOptions.find((opt) => opt.id === defaultVariantId?.toString()) || sortedOptions[0];
      onChange(defaultOption);
    }
  }, [products, defaultVariantId, selectedVariant, onChange]);

  // Get variant title from first product
  const variantTitle =
    products?.length > 0 ? `Choose Your ${products[0].variantName || 'Size'}` : 'Choose Your Size';

  return (
    <div className={styles.variantSelect}>
      <div className={styles.variantSelect__title}>{variantTitle}</div>
      <div className={styles.variantSelectItems}>
        {options.map((item, index) => (
          <div
            className={`${styles.variantSelect__item} ${
              selectedVariant?.name === item.name && styles.active
            }`}
            key={index}
            onClick={() => onChange(item)}
          >
            <i
              className={`fa ${selectedVariant?.name === item.name ? 'fa-dot-circle-o' : 'fa-circle-o'}`}
            ></i>
            <span>{item.name}</span>
          </div>
        ))}
      </div>
      {sizeGuide}
    </div>
  );
}
