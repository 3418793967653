/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import { getPriceWithCurrency, getSavingsPercent } from '../../../../utils/helpers';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function MultipleQuantity({
  specialOffer,
  onSubmit,
  offer,
  order,
  totalOffersCount,
  currentSpecialOfferNumber,
}) {
  const mergedProductsData = specialOffer.productsData.map((product) => {
    const productDetail = specialOffer.productDetails[product.id];
    return {
      ...product,
      ...productDetail,
    };
  });

  const sliderRef = useRef(null);
  const [timeLeft, setTimeLeft] = useState(600);
  const [selectedProduct, setSelectedProduct] = useState(mergedProductsData[0]);

  const discount = getSavingsPercent(selectedProduct?.oldPrice, selectedProduct?.price);
  const promotion = specialOffer.promotion;

  const hasBenefits = specialOffer.benefitItems.length > 0;
  const hasPromotion = promotion?.leftProduct?.image;
  const hasTestimonials = specialOffer.testimonials.length > 0;

  useEffect(() => {
    import('./MultipleQuantity.sass');
  }, []);

  // Timer effect
  useEffect(() => {
    if (timeLeft <= 0) return;

    const timerInterval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerInterval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timerInterval);
  }, [timeLeft]);

  // Format time as HH:MM:SS
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
      remainingSeconds
    ).padStart(2, '0')}`;
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
  };

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <>
        {[...Array(fullStars)].map((_, i) => (
          <i key={`full-${i}`} className='fa-solid fa-star'></i>
        ))}
        {halfStar && <i className='fa-duotone fa-solid fa-star-half'></i>}
        {[...Array(emptyStars)].map((_, i) => (
          <i key={`empty-${i}`} className='fa-duotone fa-solid fa-star'></i>
        ))}
      </>
    );
  };

  function renderOfferRight() {
    return (
      <div className='offer__right'>
        {mergedProductsData.length === 1 ? (
          <div className='offer__proposal'>
            <div className='offer__proposal-title'>
              <i className='fa-light fa-cart-circle-arrow-up'></i>
              {selectedProduct?.subtitle}
            </div>
            <div className='offer__proposal-info'>
              Only
              <div className='offer__proposal-price'>
                {getPriceWithCurrency(mergedProductsData[0].price / mergedProductsData[0].quantity)}
              </div>
              <span>Per {mergedProductsData[0].unit || 'Unit'}</span>
            </div>
          </div>
        ) : (
          <div className='offer__products'>
            {mergedProductsData.map((product) => (
              <button
                key={product.id}
                className={`offer__product-item ${selectedProduct.id === product.id ? 'selected' : ''}`}
                onClick={() => handleProductSelect(product)}
              >
                {product.mostPopular && <div className='offer__product-tag'>most popular</div>}
                {product.bestValue && (
                  <div className='offer__product-tag offer__product-tag--best-value'>best value</div>
                )}

                <div className='offer__product-content'>
                  <img src={product.image} alt='' />
                  <div className='offer__product-info'>
                    <div className='offer__product-quantity'>{product.quantity}x</div>
                    <div className='offer__product-text'>{product.subtitle}</div>
                    <div className='offer__product-price-new'>{getPriceWithCurrency(product.price)}</div>
                    <div className='offer__product-price-old'>{getPriceWithCurrency(product.oldPrice)}</div>
                  </div>
                </div>
              </button>
            ))}
          </div>
        )}
        <button className='button-submit offer__button-submit' onClick={() => onSubmit(selectedProduct)}>
          Yes, Add This To My Order
        </button>
        <button className='button-cancel offer__button-cancel' onClick={() => onSubmit()}>
          No thanks, I don’t want to take advantage of this one-time offer
        </button>
      </div>
    );
  }

  return (
    <div className='MultipleQuantity'>
      <header className='header'>
        <div className='header-top'>
          <div className='header-top__container'>
            <i className='fa-solid fa-badge-percent'></i>
            Special limited time offer
            <span>up to {discount}% OFF</span>
            <div className='header-top__timer js-timer'>{formatTime(timeLeft)}</div>
          </div>
        </div>
        <div className='header-bottom'>
          <div className='header-bottom__container'>
            <div className='h3-title header-bottom__title'>
              Take advantage of this special deal before time runs out!
            </div>
            <div className='header-bottom__progress'>
              <div className='header-bottom__progress-bar'>
                <div className='header-bottom__progress-active'></div>
              </div>
              <div className='header-bottom__progress-list'>
                <div className='header-bottom__progress-step'>Your order</div>
                <div className='header-bottom__progress-step'>
                  Special offers {currentSpecialOfferNumber} / {totalOffersCount}
                </div>
                <div className='header-bottom__progress-step'>
                  <i className='fa-sharp fa-solid fa-flag-checkered'></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className='content'>
        <div className='content__container'>
          <h1 className='h1-title content__title'>
            {specialOffer.headline} <span className='blue-color'>at {discount}% OFF!</span>
          </h1>
          <h3 className='h3-title content__subtitle'>{specialOffer.subHeadline}</h3>
          <div className='content__desc'>{specialOffer.description}</div>
          <div className='horizontal-line content__line'></div>

          <div className='offer'>
            <div className='offer__left'>
              <img src={specialOffer.productImage} alt='product' className='offer__left-img' />

              <div className='offer__left-bottom'>
                <i className='fa-solid fa-thumbs-up'></i>
                <div className='offer__left-text'>Best Seller</div>
              </div>
            </div>
            {renderOfferRight()}
          </div>

          <div className='horizontal-line content__line'></div>

          <div className='features'>
            {specialOffer.features.map((feature, index) => (
              <div className='features__item' key={index}>
                <span dangerouslySetInnerHTML={{ __html: feature.icon }}></span>
                <div className='features__item-info'>
                  <div className='features__item-title'>{feature.title}</div>
                  <div className='features__item-text'>{feature.description}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {hasTestimonials && (
        <section className='testimonials'>
          <div className='testimonials__container'>
            <ul className='testimonials__stars'>
              <li>
                <i className='fa-solid fa-star'></i>
              </li>
              <li>
                <i className='fa-solid fa-star'></i>
              </li>
              <li>
                <i className='fa-solid fa-star'></i>
              </li>
              <li>
                <i className='fa-solid fa-star'></i>
              </li>
              <i className='fa-duotone fa-solid fa-star-half'></i>
            </ul>
            <div className='testimonials__subtitle'>
              More than <b>30,050+</b> satisfied customers
            </div>
            <h2 className='h2-title testimonials__title'>See What Happy Customers Have to Say</h2>

            <Slider
              ref={sliderRef}
              className='testimonials__slider'
              infinite={true}
              arrows={false}
              slidesToShow={3}
              slidesToScroll={1}
              responsive={[
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    adaptiveHeight: true,
                  },
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    adaptiveHeight: true,
                  },
                },
              ]}
            >
              {specialOffer.testimonials.map((testimonial, index) => (
                <div key={index} className='testimonials__slider-slide'>
                  <div className='testimonials__slider-text'>"{testimonial.text}"</div>
                  <div className='testimonials__slider-author'>- {testimonial.author}</div>
                  <div className='testimonials__slider-verified'>
                    <i className='fa-solid fa-badge-check'></i> Verified Customer
                  </div>
                  <div className='testimonials__slider-stars'>{renderStars(testimonial.stars)}</div>
                </div>
              ))}
            </Slider>

            <div className='testimonials__buttons'>
              <button
                className='button-submit testimonials__button-submit'
                onClick={() => onSubmit(selectedProduct)}
              >
                Yes, Add This To My Order
              </button>
              <button className='button-cancel testimonials__button-cancel' onClick={() => onSubmit()}>
                No thanks, I don't want to take advantage of this one-time offer
              </button>
            </div>
          </div>
        </section>
      )}

      {hasBenefits && (
        <section className='benefits'>
          <div className='benefits__container'>
            {specialOffer.benefitItems.map((benefit, index) => (
              <div className='benefits__item' key={index}>
                <img src={benefit.image} alt={benefit.title} />
                <div className='benefits__info'>
                  <h3 className='h3-title benefits__title'>{benefit.title}</h3>
                  <div className='benefits__text'>{benefit.text}</div>
                </div>
              </div>
            ))}
            <div className='testimonials__buttons'>
              <button
                className='button-submit testimonials__button-submit'
                onClick={() => onSubmit(selectedProduct)}
              >
                Yes, Add This To My Order
              </button>
              <button className='button-cancel testimonials__button-cancel' onClick={() => onSubmit()}>
                No thanks, I don't want to take advantage of this one-time offer
              </button>
            </div>
          </div>
        </section>
      )}

      {hasBenefits && hasTestimonials && hasPromotion && (
        <section className='content'>
          <div className='content__container'>
            <div className='offer'>
              <div className='offer__left'>
                <img src={specialOffer.productImage} alt='product' className='offer__left-img' />

                <div className='offer__left-bottom'>
                  <i className='fa-solid fa-thumbs-up'></i>
                  <div className='offer__left-text'>Best Seller</div>
                </div>
              </div>
              {renderOfferRight()}
            </div>

            <div className='horizontal-line content__line'></div>

            <div className='features'>
              {specialOffer.features.map((feature, index) => (
                <div className='features__item' key={index}>
                  <span dangerouslySetInnerHTML={{ __html: feature.icon }}></span>
                  <div className='features__item-info'>
                    <div className='features__item-title'>{feature.title}</div>
                    <div className='features__item-text'>{feature.description}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}

      {hasPromotion && (
        <section className='promotion'>
          <div className='promotion__container'>
            <h2 className='h2-title promotion__title'>{promotion.title}</h2>
            <div className='promotion__offer'>
              <div className='promotion__offer-images'>
                <div className='promotion__offer-card'>
                  <img src={promotion?.leftProduct?.image} alt={promotion.leftProduct?.title} />
                  <div className='promotion__offer-card-title'>{promotion.leftProduct?.title}</div>
                </div>
                <i className='fa-solid fa-circle-plus'></i>
                <div className='promotion__offer-card'>
                  <img src={promotion.rightProduct?.image} alt={promotion.rightProduct?.title} />
                  <div className='promotion__offer-card-title'>{promotion.rightProduct?.title}</div>
                  {promotion.rightProduct?.subtitle && (
                    <div className='promotion__offer-card-text'>{promotion.rightProduct?.subtitle}</div>
                  )}
                </div>
              </div>
              <div className='promotion__offer-info'>
                <div className='promotion__offer-title'>{promotion.offerTitle}</div>
                <ul className='promotion__offer-list'>
                  {promotion.bullets?.map((bullet, index) => (
                    <li key={index}>
                      <i className='fa-solid fa-badge-check'></i>
                      {bullet}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='testimonials__buttons'>
              <button
                className='button-submit testimonials__button-submit'
                onClick={() => onSubmit(selectedProduct)}
              >
                Yes, Add This To My Order
              </button>
              <button className='button-cancel testimonials__button-cancel' onClick={() => onSubmit()}>
                No thanks, I don't want to take advantage of this one-time offer
              </button>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
