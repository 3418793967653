import React, { useState, useEffect } from 'react';
import styles from './Reviews.module.sass';

const Reviews = ({ reviews }) => {
  const [likeCounts, setLikeCounts] = useState({});

  useEffect(() => {
    // Initialize like counts from localStorage or generate new ones
    if (reviews && reviews.length > 0) {
      const storedLikeCounts = localStorage.getItem('reviewLikeCounts');
      let newLikeCounts = {};

      if (storedLikeCounts) {
        newLikeCounts = JSON.parse(storedLikeCounts);

        // Check if we have the right number of reviews
        if (Object.keys(newLikeCounts).length !== reviews.length) {
          // Reset and generate new counts for all reviews
          newLikeCounts = {};
          for (let i = 0; i < reviews.length; i++) {
            newLikeCounts[i] = Math.floor(Math.random() * 31) + 60;
          }
        }
      } else {
        // Generate new like counts for all reviews
        for (let i = 0; i < reviews.length; i++) {
          newLikeCounts[i] = Math.floor(Math.random() * 31) + 60;
        }
      }

      // Save the counts and update state
      localStorage.setItem('reviewLikeCounts', JSON.stringify(newLikeCounts));
      setLikeCounts(newLikeCounts);
    }
  }, [reviews]);

  return (
    <div className={styles.reviewsContainer}>
      {reviews.map((review, index) => (
        <div key={review.id} className={styles.review}>
          <img src={review.imageSrc} alt={review.name} className={styles.userImage} />
          <div className={styles.reviewContent}>
            <div className={styles.reviewHeader}>
              <p className={styles.userName}>{review.name}</p>
              <div className={styles.dot}></div>
              <p className={styles.userLocation}>{review.location}</p>
            </div>
            <p className={styles.reviewText}>{review.text}</p>
            <div className={styles.reviewFooter}>
              <button className={styles.replyButton}>Reply</button>
              <div className={styles.dot}></div>
              <button className={styles.replyButton}>Like</button>
              <div className={styles.dot}></div>
              <button className={styles.likeButton}>
                <i className='fa-regular fa-thumbs-up'></i>
                <span className={styles.likeCount}>{likeCounts[index] || 0}</span>
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Reviews;
