import { useState } from 'react';
import './ImageOnly.sass';
import { getPriceWithCurrency } from '../../../../utils/helpers';

export default function ImageOnly({ order, specialOffer, onSubmit, offer }) {
  const mergedProductsData = specialOffer.productsData.map((product) => {
    const productDetail = specialOffer.productDetails[product.id];

    return {
      ...product,
      ...productDetail,
    };
  });

  const [selectedProduct, setSelectedProduct] = useState(mergedProductsData[0]);

  function renderProductSelector() {
    return (
      <select
        className='special-offer__select'
        name='productSelect'
        value={selectedProduct.id}
        onChange={(e) => setSelectedProduct(mergedProductsData.find((item) => item.id === e.target.value))}
      >
        {mergedProductsData.map((item, index) => {
          return (
            <option key={index} value={item.id}>
              {item.qty} {item.name} for {getPriceWithCurrency(item.price, offer?.locale, offer?.currency)}
              {item.qty > 1 && '/ea'}
            </option>
          );
        })}
      </select>
    );
  }

  return (
    <main className='special-offer'>
      <div className='special-offer__title'>Wait, {order.firstName || 'dear customer'}!</div>
      <div
        className='special-offer__subtitle'
        dangerouslySetInnerHTML={{ __html: specialOffer.headline }}
      ></div>
      {specialOffer.gallery.map((item, index) => (
        <img key={index} src={item.source} alt='special offer' className='special-offer__img' />
      ))}
      {mergedProductsData.length > 1 && renderProductSelector()}
      <div className='special-offer__buttons'>
        <button className='special-offer__buttons--add' onClick={() => onSubmit(selectedProduct)}>
          Yes! Add {selectedProduct.qty} {specialOffer.productName} to my order for{' '}
          {getPriceWithCurrency(selectedProduct.price, offer?.locale, offer?.currency)}
          {selectedProduct.qty > 1 && '/ea'}
        </button>
        <button className='special-offer__buttons--skip' onClick={() => onSubmit()}>
          No, I don't want it
        </button>
        {specialOffer.disclaimer && (
          <small className='special-offer__disclaimer'>{specialOffer.disclaimer}</small>
        )}
      </div>
    </main>
  );
}
