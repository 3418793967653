import queryString from 'query-string';
import base64js from 'base64-js';

export function mapVariantsFromProducts(products) {
  const variants = [];

  products.forEach((product) => {
    if (!variants.find((item) => item.name === product.color)) {
      variants.push({
        name: product.color,
        imageSrc: product.colorImage || product.colorimage,
      });
    }
  });

  return variants;
}

export function buildQueryParams(location, saveCase = false) {
  const queryParams = {};
  const qp = saveCase ? queryString.parse(location.search) : queryString.parse(location.search.toLowerCase()); // lower cased to prevent typos

  // If URL has multiple params with same name we going to use last one provided
  Object.keys(qp).forEach((key) => {
    queryParams[key] = typeof qp[key] === 'object' ? qp[key][qp[key].length - 1] : qp[key];
  });

  return queryParams;
}

export function getPriceWithCurrency(price, locale = 'en-US', currency = 'USD') {
  if (!price) {
    return '';
  }

  // if price has $ sign remove it first
  if (typeof price === 'string' && price.includes('$')) {
    price = price.replace('$', '');
  }

  return (price * 1).toLocaleString(locale, { style: 'currency', currency });
}

// Function to convert a string to base64
export function stringToBase64(str) {
  // Convert the string to a byte array
  const byteArray = new TextEncoder().encode(str);

  // Convert the byte array to base64
  const base64 = base64js.fromByteArray(byteArray);

  return base64;
}

export function getAffiliateCbValue(dataLayer) {
  for (let i = 0; i < dataLayer.length; i++) {
    if (dataLayer[i].hasOwnProperty('affiliateCb')) {
      return dataLayer[i].affiliateCb;
    }
  }
  return null;
}

export function getSavingsPercent(oldPrice, price) {
  const decrease = oldPrice - price;
  return Math.round((decrease / oldPrice) * 100) || 0;
}

export function getMaxDiscount(offer) {
  let result = 0;

  offer.productsData.forEach((item) => {
    const percent = getSavingsPercent(item.oldPrice, item.price);

    if (percent > result) {
      result = percent;
    }
  });

  return `${result.toFixed()}%`;
}
