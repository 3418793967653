import createDataContext from './createDataContext';
import api from '../api/api';

const offerReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_OFFER_REQUEST':
      return {
        ...state,
        loading: true,
        error: false,
      };
    case 'LOAD_OFFER_SUCCESS':
      return {
        ...state,
        loading: false,
        offer: action.payload,
      };
    case 'MERGE_OFFER_WITH_PROMO':
      return {
        loading: false,
        ...state,
        offer: {
          ...state.offer,
          ...action.payload,
        },
      };
    case 'LOAD_OFFER_ERROR':
      return {
        ...state,
        loading: false,
        error: 'Unable to load offer',
      };
    default:
      return state;
  }
};

const loadOffer = (dispatch) => async (id) => {
  try {
    dispatch({
      type: 'LOAD_OFFER_REQUEST',
    });
    const response = await api.get(`/offers/${id}`);
    dispatch({
      type: 'LOAD_OFFER_SUCCESS',
      payload: response.data,
    });
    return response.data;
  } catch (e) {
    dispatch({
      type: 'LOAD_OFFER_ERROR',
    });
  }
};

const mergeOfferWithPromo = (dispatch) => async (promo) => {
  const promoUpdates = {};

  if (!promo) {
    console.error('No promo data provided for merging');
    return;
  }

  // Define keys that should be merged
  const keysToMerge = [
    'productImageSrc',
    'sizeGuideImage',
    'productIds',
    'productsData',
    'specialOfferIds',
    'productDetails',
    'title',
    'category',
    'benefits',
    'campaignId',
  ];

  keysToMerge.forEach((key) => {
    const value = promo[key];

    // Skip undefined or null values
    if (value === undefined || value === null) return;

    // For arrays, only merge if they have items
    if (Array.isArray(value)) {
      if (value.length > 0) {
        promoUpdates[key] = value;
      }
      return;
    }

    // For objects (excluding arrays), only merge if they have properties
    if (typeof value === 'object') {
      if (Object.keys(value).length > 0) {
        promoUpdates[key] = value;
      }
      return;
    }

    // For strings, only merge if not empty
    if (typeof value === 'string') {
      if (value !== '') {
        promoUpdates[key] = value;
      }
      return;
    }

    // For numbers, booleans, or other primitives, include them all
    promoUpdates[key] = value;
  });

  dispatch({
    type: 'MERGE_OFFER_WITH_PROMO',
    payload: promoUpdates,
  });
};

export const { Context, Provider } = createDataContext(
  offerReducer,
  {
    mergeOfferWithPromo,
    loadOffer,
  },
  {
    offer: {},
    error: '',
    loading: false,
  }
);
