import React, { useState, useEffect, useContext } from 'react';
import FreeProductList from '../../../../components/FreeProductList/FreeProductList';
import { Context as FreeProductsContext } from '../../../../context/FreeProductsContext';
import useInterval from '../../../../hooks/useInterval';

export default function FreeProducts({ offer, onSubmit }) {
  const { upsellSettings: settings } = offer;
  const [seconds, setSeconds] = useState(60);
  const [isRunning, setIsRunning] = useState(true);
  const {
    state: { freeProducts },
  } = useContext(FreeProductsContext);

  function countdown() {
    if (seconds <= 0) {
      document.getElementById('countdown').innerHTML = '<span>0</span><span>0</span>';
    } else {
      const first = seconds >= 10 ? seconds.toString()[0] : 0;
      const second = seconds >= 10 ? seconds.toString()[1] : seconds;
      document.getElementById('countdown').innerHTML = `<span>${first}</span><span>${second}</span>`;
    }
    setSeconds(seconds - 1);
  }

  function handleSubmit(product) {
    onSubmit(product);
  }

  useInterval(
    () => {
      settings?.lastChancePage?.countdown && countdown();
    },
    isRunning ? 1000 : null
  );

  useEffect(() => {
    import('./FreeProducts.sass');
    setIsRunning(true);
  }, []);

  return (
    <main className='free-products'>
      <div className='free-products__warning'>HURRY UP! LIMITED QUANTITY AVAILABLE</div>
      <div className='free-products__header'>
        <h4>
          {settings.lastChancePage?.title}

          {settings.lastChancePage?.countdown && (
            <div id='countdown'>
              <span>6</span>
              <span>0</span>
            </div>
          )}
        </h4>
        <p>{settings.lastChancePage?.subtitle}</p>
      </div>
      {settings.template === 'oneProduct' && (
        <div className='free-product-element'>
          <img src={settings.oneProductSettings?.imgUrl} alt='free product' />
          <button
            className='free-product-list-product-btn'
            onClick={() => onSubmit(settings.oneProductSettings)}
          >
            Click here to claim gift
          </button>
        </div>
      )}

      {settings.template === 'freeProducts' && (
        <FreeProductList productList={freeProducts?.data?.page} onProductClick={onSubmit} />
      )}

      <div className='free-products__description'>
        {settings.template === 'freeProducts' && (
          <i className='far fa-chevron-double-down animate__animated animate__fadeInDown animate__infinite animate__slow'></i>
        )}
        {settings.terms}
      </div>
      <div className='free-product-modal-buttons-btn' id='warranty-no' onClick={() => handleSubmit()}>
        NO, I DON’T LIKE FREE STUFF
      </div>
    </main>
  );
}
